import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform, getSupportedInputTypes } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { Directive, DoCheck, ElementRef, HostBinding, Inject, InjectionToken, Input, OnChanges, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ErrorStateMatcher, UIKIT_FORM_FIELD, UIKitFormField } from '../form-field/form-field';
import { UIKitFormFieldControl } from '../form-field/form-field-control';

let nextUniqueId = 0;
export const UIKIT_INPUT_VALUE_ACCESSOR =
  new InjectionToken<{ value: any }>('UIKIT_INPUT_VALUE_ACCESSOR');
@Directive({
  selector: 'input[uikit-input], textarea[uikit-input]',
  host: {
    // Native input properties need to be synced
    '[attr.id]': 'id',
    '[attr.placeholder]': 'placeholder',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.readonly]': 'readonly && !_isNativeSelect || null',
    '(blur)': '_focusChanged(false)',
    '(focus)': '_focusChanged(true)'
  },
  providers: [{ provide: UIKitFormFieldControl, useExisting: UIKitInput }]
})
export class UIKitInput implements UIKitFormFieldControl<any>, OnInit, OnDestroy, DoCheck, OnChanges {

  controlType = 'uikit-input';
  protected _uid = `uikit-input-${nextUniqueId++}`;
  @Input() errorState = false;
  focused = false;
  autofilled = false;
  errorStateMatcher: ErrorStateMatcher;
  readonly stateChanges: Subject<void> = new Subject<void>();
  private valueChangesSubscription: Subscription;

  @HostBinding('class.host-selected') @Input() selected;

  @HostBinding('class.uikit-input') get uikitInput() { return true; }
  @Input() placeholder: string;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  protected _disabled = false;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: BooleanInput) { this._required = coerceBooleanProperty(value); }
  protected _required = false;
  private _inputValueAccessor: { value: any };

  @Input()
  get id(): string { return this._id; }
  set id(value: string) { this._id = value || this._uid; }
  protected _id: string;

  @Input()
  get type(): string { return this._type; }
  set type(value: string) {
    this._type = value || 'text';
    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this._isTextarea() && getSupportedInputTypes().has(this._type)) {
      (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
    }
  }

  @Input()
  get value(): string { return this._inputValueAccessor?.value; }
  set value(value: string) {
    if (value !== this.value) {
      if (!this._inputValueAccessor) {
        this._inputValueAccessor = { value: value }
      } else {
        this._inputValueAccessor.value = value;
      }
      this.stateChanges.next();
    }
  }

  @Input()
  get readonly(): boolean { return this._readonly; }
  set readonly(value: boolean) { this._readonly = coerceBooleanProperty(value); }
  private _readonly = false;
  protected _type = 'text';

  protected _neverEmptyInputTypes = [
    'date',
    'datetime',
    'datetime-local',
    'month',
    'time',
    'week'
  ].filter(t => getSupportedInputTypes().has(t));

  get empty(): boolean {
    return !this._isNeverEmpty()
      && !this._elementRef.nativeElement.value
      && !this._isBadInput()
      && !this.autofilled;
  }

  ngOnInit() {
    if (this._platform.isBrowser) {
      this._autofillMonitor.monitor(this._elementRef.nativeElement).subscribe(event => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }

    if (this.ngControl) {
      this.valueChangesSubscription = this.ngControl.valueChanges?.subscribe(value => {
          this._inputValueAccessor.value = value;
          this.stateChanges.next();
      });
    }
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.stateChanges.complete();
    if (this._platform.isBrowser) {
      this._autofillMonitor.stopMonitoring(this._elementRef.nativeElement);
    }
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  constructor(protected _elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    protected _platform: Platform,
    private _autofillMonitor: AutofillMonitor,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() @Inject(UIKIT_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Inject(UIKIT_FORM_FIELD) protected _formField?: UIKitFormField) {

    const element = this._elementRef.nativeElement;
    this._inputValueAccessor = inputValueAccessor || element;
    // Force setter if id was not specified.
    // eslint-disable-next-line no-self-assign
    this.id = this.id;
  }

  protected _isTextarea() {
    return this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
  }

  protected _isNeverEmpty() {
    return this._neverEmptyInputTypes.indexOf(this._type) > -1;
  }
  /** Checks whether the input is invalid based on the native validation. */
  protected _isBadInput() {
    const validity = (this._elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  updateErrorState() {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = this.errorStateMatcher || this._defaultErrorStateMatcher;
    const control = this.ngControl ? (this.ngControl.control as AbstractControl) : null;
    const newState = matcher.isErrorState(control, parent);
    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
}
