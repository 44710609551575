<div class="dialog-container"
  [class.warning-confirmation-container]="data.type === DialogType.Warning || data.type === DialogType.Delete || data.type === DialogType.Error"
  [class.no-color]="data.type === DialogType.Input">
  <div [ngSwitch]="isTitleString()">
    <h3 *ngSwitchCase="true" [innerHTML]="getTitle() | transloco"></h3>
    <h3 *ngSwitchDefault [innerHTML]="getTitle().key | transloco: { value: getTitle().value }"></h3>
  </div>
  <div class="inner-container">
    <p *ngIf="data.description">{{ data.description | transloco }}</p>
    <span class="text" *ngIf="data.body"
      [innerHTML]="data.body | transloco: { param: data.translationParameter } "></span>
    <uikit-form-field *ngIf="data.type === DialogType.Input">
      <uikit-label>{{ data.label | transloco }}</uikit-label>
      <input type="text" uikit-input [placeholder]="data.placeholder" [(ngModel)]="data.value" />
    </uikit-form-field>
  </div>
  <div class="action-container">
    <ng-container *ngTemplateOutlet="data.type === DialogType.Info
    ? info
    : (data.type === DialogType.Delete
      ? delete
      : (data.type === DialogType.Input
        ? input
        : (data.type === DialogType.Confirm
          ? info
          : default)))"></ng-container>
  </div>
</div>

<ng-template #delete>
  <button uikit-button delete min-width (click)="dialogRef.close(true)">{{ data.trueOption | transloco }}</button>
  <button uikit-button color="ghost" min-width (click)="dialogRef.close(false)">{{ data.falseOption | transloco
    }}</button>
</ng-template>

<ng-template #default>
  <button uikit-button delete color="secondary" min-width (click)="dialogRef.close(false)">{{ data.falseOption |
    transloco }}</button>
  <button uikit-button min-width (click)="dialogRef.close(true)">{{ data.trueOption | transloco }}</button>
</ng-template>

<ng-template #info>
  <button uikit-button min-width (click)="dialogRef.close(true)" [attr.data-pendo-id]="data.trueOption">{{
    data.trueOption |
    transloco }}</button>
  <button *ngIf="data.falseOption" uikit-button color="ghost" min-width (click)="dialogRef.close(false)">{{
    data.falseOption |
    transloco }}</button>
</ng-template>

<ng-template #input>
  <form>
    <button uikit-button min-width (click)="dialogRef.close(data.value ? data.value : data.placeholder)">{{
      data.trueOption |
      transloco }}</button>
    <button uikit-button color="ghost" min-width (click)="dialogRef.close(false)">{{ data.falseOption | transloco
      }}</button>
  </form>
</ng-template>
