import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'app/shared';
import {
  ActivateSurvey,
  AddReminderViewModel,
  AnonymityResultModel,
  AutoPPTData,
  ChapterWithQuestions,
  ChapterWithQuestionsAndTranslations,
  CreateSurveyReminder,
  EditSurveyModel,
  PinCodeViewModel,
  Survey,
  SurveyCreate,
  SurveyDetailsModel,
  SurveyIndexWithQuestion,
  SurveyList,
  SurveyListModel,
  SurveyMenuItem,
  SurveyPortalContext,
  SurveyResponse,
  SurveyStatus,
  SurveyQuestionsAndAnswers
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  getContext(token: string): Observable<SurveyPortalContext> {
    return this._http.get<SurveyPortalContext>(`${this._config.apiUrl}/api/survey-users/context/${token}`, { headers: { 'init': 'true' } });
  }
  get(token: string, languageId?: number): Observable<Survey> {
    let params = new HttpParams();
    if (token) {
      params = params.set('token', token);
    }
    if (languageId) {
      params = params.set('languageId', languageId.toString());
    }
    return this._http.get<Survey>(`${this._config.apiUrl}/api/survey-users`, { params: params });
  }

  getByModuleId(moduleId: number): Observable<Survey[]> {
    return this._http.get<Survey[]>(`${this._config.apiUrl}/api/surveys/byModuleId/${moduleId}`);
  }

  getState(token: string): Observable<string> {
    return this._http.get<string>(`${this._config.apiUrl}/api/survey-users/state?token=${token}`);
  }

  updateState(token: string, state: string): Observable<HttpResponse<unknown>> {
    return this._http.put(`${this._config.apiUrl}/api/survey-users/state`, { 'token': token, 'state': state },
      { observe: 'response', responseType: 'text' });
  }

  submit(model: SurveyResponse): Observable<HttpResponse<unknown>> {
    return this._http.post(`${this._config.apiUrl}/api/survey-users`, model, { observe: 'response', responseType: 'text' });
  }

  create(companyId: number, survey: SurveyCreate): Observable<HttpResponse<string>> {
    return this._http
      .post(`${this._config.apiUrl}/api/companies/${companyId}/surveys`, survey, { observe: 'response', responseType: 'text' });
  }

  update(companyId: number, surveyId: number, survey: SurveyCreate): Observable<HttpResponse<unknown>> {
    return this._http.put<HttpResponse<unknown>>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}`, survey);
  }

  activate(companyId: number, surveyId: number, activateSurvey: ActivateSurvey): Observable<HttpResponse<unknown>> {
    return this._http
      .post<HttpResponse<unknown>>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/activate`, activateSurvey);
  }
  
  deactivate(surveyId: number): Observable<HttpResponse<unknown>> {
    return this._http
      .delete<HttpResponse<unknown>>(`${this._config.apiUrl}/api/surveys/${surveyId}/deactivate`);
  }

  updateSurveyAndTask(id: number, survey: EditSurveyModel): Observable<HttpResponse<unknown>> {
    return this._http.put<HttpResponse<unknown>>(`${this._config.apiUrl}/api/surveys/${id}/update-survey-and-task`, survey);
  }

  list(companyId: number): Observable<SurveyList[]> {
    return this._http
      .get<SurveyList[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys`);
  }

  listByStatus(companyId: number, surveyStatus: SurveyStatus): Observable<SurveyList[]> {
    return this._http
      .get<SurveyList[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyStatus}`)
      .pipe(map(surveys => {
        for (const survey of surveys) {
          survey.isCustomName = !!survey.surveyName;
          survey.responseRate = (survey.participantCount === 0) ? 0 : survey.responseCount / survey.participantCount;
        }
        return surveys;
      }));
  }

  listByGroupByStatus(groupId: number, surveyStatus: SurveyStatus): Observable<SurveyList[]> {
    return this._http
      .get<SurveyList[]>(`${this._config.apiUrl}/api/groups/${groupId}/surveys/${surveyStatus}`);
  }

  details(companyId: number, surveyId: number): Observable<SurveyDetailsModel> {
    return this._http
      .get<SurveyDetailsModel>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/details`)
      .pipe(map(model => ({
        ...model,
        startDate: new Date(model.startDate),
        endDate: new Date(model.endDate),
        reportDate: model.reportDate == null ? null : new Date(model.reportDate),
        reminders: model.reminders.map(reminder => ({ ...reminder, date: new Date(reminder.date) }))
      })));
  }

  pinCodes(companyId: number, surveyId: number): Observable<PinCodeViewModel[]> {
    return this._http
      .get<PinCodeViewModel[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/pin-codes`);
  }

  anonymityResult(surveyId: number): Observable<Record<string, AnonymityResultModel[]>> {
    return this._http
      .get<Record<string, AnonymityResultModel[]>>(`${this._config.apiUrl}/api/surveys/${surveyId}/anonymity-result`);
  }

  getSurveyIndexQuestionsCount(companyId: number, surveyId: number): Observable<SurveyIndexWithQuestion[]> {
    return this._http
      .get<SurveyIndexWithQuestion[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/index-questions-count`);
  }

  getSurveyQuestions(companyId: number, surveyId: number): Observable<ChapterWithQuestions[]> {
    return this._http
      .get<ChapterWithQuestions[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/questions`);
  }

  getSurveyQuestionsWithTranslations(companyId: number, surveyId: number): Observable<ChapterWithQuestionsAndTranslations[]> {
    return this._http
      .get<ChapterWithQuestionsAndTranslations[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/surveyquestions`);
  }

  listForUser(companyId: number, userId: number): Observable<SurveyListModel[]> {
    return this._http
      .get<SurveyListModel[]>(`${this._config.apiUrl}/api/companies/${companyId}/users/${userId}/surveys`);
  }

  getPinSurveys(companyId: number): Observable<SurveyListModel[]> {
    return this._http
      .get<SurveyListModel[]>(`${this._config.apiUrl}/api/companies/${companyId}/pin-surveys`);
  }

  removeUsers(surveyId: number, userIds: number[]): Observable<SurveyDetailsModel> {
    return this._http
      .post<SurveyDetailsModel>(`${this._config.apiUrl}/api/surveys/${surveyId}/remove-users`, userIds);
  }

  removeDeletedUsers(surveyId: number): Observable<number> {
    return this._http
      .delete<number>(`${this._config.apiUrl}/api/surveys/${surveyId}/remove-deleted-users-in-survey`);
  }

  getAutoPPTData(companyId: number, snapshotIds: number[]): Observable<AutoPPTData> {
    return this._http.get<AutoPPTData>(`${this._config.apiUrl}/api/surveys/${companyId}/autoppt`, 
    {
      params: { snapshotIds }
    });
  }

  surveysForMenu(companyId: number): Observable<SurveyMenuItem[]> {
    return this._http.get<SurveyMenuItem[]>(`${this._config.apiUrl}/api/companies/${companyId}/mysurveys`);
  }

  surveysForMenuFromToken(token: string): Observable<SurveyMenuItem[]> {
    return this._http.get<SurveyMenuItem[]>(`${this._config.apiUrl}/api/surveys/${token}/mysurveys`);
  }

  getQuestionsAndAnswersFromSurvey(surveyId: number, userId: number): Observable<SurveyQuestionsAndAnswers> {
    return this._http.post<SurveyQuestionsAndAnswers>(`${this._config.apiUrl}/api/surveys/questionsandanswers/${userId}`, surveyId);
  }
}
